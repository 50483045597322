import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
var baseTitle='宏瓴科技'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/CompanyDynamic',
    name: 'CompanyDynamic',
    component: () => import('../views/CompanyDynamic.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/Service',
    name: 'Service',
    component: () => import('../views/Service.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/UBIService',
    name: 'UBIService',
    component: () => import('../views/UBIService.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/NEVService',
    name: 'NEVService',
    component: () => import('../views/NEVService.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/SecurityService',
    name: 'SecurityService',
    component: () => import('../views/SecurityService.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/ControlService',
    name: 'ControlService',
    component: () => import('../views/ControlService.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
	},
  },
  {
    path: '/JoinUs',
    name: 'JoinUs',
    component: () => import('../views/JoinUs.vue'),
	meta: {
		title: baseTitle,
		keepAlive:false
  },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
