<template>
    <div class="wrap" :class="{ wrap_en: $i18n.locale == 'EN' }">
        <my-header></my-header>
        <div class="frist_page">
            <video autoplay="autoplay" muted="muted" loop="loop" @loadeddata="videoLoaded" poster="../../public/images/homepage/banner.mp4.thumbnail.jpg" disablePictureInPicture>
                <source src="../../public/images/homepage/banner.mp4" type="video/mp4" />
            </video>
            <!-- <div class="frist_gif"><img src="../../public/images/homepage/banner-gif.gif" ></div> -->
            <!-- <video class="video_2" autoplay="autoplay" muted="muted" loop="loop">
            <source src="../../public/images/homepage/banner_2.mp4" type="video/mp4" />
        </video> -->
            <div class="circle-container" v-if="isVideoLoaded">
                <div class="circle-text">
                    <span class="text-base text1">RLHF</span>
                    <!-- Replicate the span with different content and add an animation delay to each for a continuous effect -->
                </div>
            </div>
            <div class="circle-container" v-if="isVideoLoaded">
                <div class="circle-text">
                    <span class="text-base text2" style="">GNN</span>
                </div>
            </div>
            <div class="circle-container" v-if="isVideoLoaded">
                <div class="circle-text">
                    <span class="text-base text3" style="">LoRA</span>
                </div>
            </div>
            <div class="circle-container" v-if="isVideoLoaded">
                <div class="circle-text">
                    <span class="text-base text4" style="">Transformer</span>
                </div>
            </div>
            <div class="circle-container" v-if="isVideoLoaded">
                <div class="circle-text">
                    <span class="text-base text5" style="">GPT</span>
                </div>
            </div>
            <div class="banner_text">
                <transition name="slide-fade">
                    <div v-if="show">
                        <!-- <p>{{$t('Public.Home.banner.title1')+" "+$t('Public.Home.banner.title2')}}</p> -->
                        <p>{{ $t('Public.Home.banner.title') }}</p>
                        <div>{{ $t('Public.Home.banner.content') }}</div>
                    </div>
                </transition>
            </div>
            <div class="page_slogan">{{ $t('Public.Home.banner.slogan') }}</div>
            <div class="scrollBottom" @click="scrollToSecondpage">
                <i></i>
                <i></i>
            </div>
        </div>
        <div class="second_page">
            <div style="min-height: 2.6rem">
                <transition name="slide-fade">
                    <div v-if="secondPageShow">
                        <p class="page_title">{{ $t('Public.Home.aboutUs.title') }}</p>
                        <div class="second_text">{{ $t('Public.Home.aboutUs.content1') }}</div>
                        <div class="second_text">{{ $t('Public.Home.aboutUs.content2') }}</div>
                        <div class="second_text">{{ $t('Public.Home.aboutUs.content3') }}</div>
                    </div>
                </transition>
            </div>

            <video class="second_img" autoplay="autoplay" muted="muted" loop="loop" disablePictureInPicture>
                <source src="../../public/images/homepage/sigreal.mp4" type="video/mp4" />
            </video>
            <!-- <div class="second_img"><img src="../../public/images/homepage/sigreal.png" ></div> -->
        </div>
        <div class="third_page" v-if="$i18n.locale == 'EN'">
            <transition name="slide-fade">
                <div v-if="thirdPageShow">
                    <p class="page_title">{{ $t('Public.Home.ourCulture.title') }}</p>
                    <div class="culture_wall">
                        <div class="line1"></div>
                        <div class="line2"></div>
                        <div class="line3"></div>
                        <ul :class="{ active: $i18n.locale == 'EN' }">
                            <li>
                                <div><img src="../../public/images/homepage/icon_chengjiu.png" /></div>
                                <p>{{ $t('Public.Home.ourCulture.cultureTitle[0]') }}</p>
                                <span>{{ $t('Public.Home.ourCulture.cultureIntro[0]') }}</span>
                            </li>
                            <li>
                                <div><img src="../../public/images/homepage/icon_wushi.png" /></div>
                                <p>{{ $t('Public.Home.ourCulture.cultureTitle[1]') }}</p>
                                <span>{{ $t('Public.Home.ourCulture.cultureIntro[1]') }}</span>
                            </li>
                            <li>
                                <div><img src="../../public/images/homepage/icon_zhengzhi.png" /></div>
                                <p>{{ $t('Public.Home.ourCulture.cultureTitle[2]') }}</p>
                                <span>{{ $t('Public.Home.ourCulture.cultureIntro[2]') }}</span>
                            </li>
                            <li>
                                <div><img src="../../public/images/homepage/icon_manzugan.png" /></div>
                                <p>{{ $t('Public.Home.ourCulture.cultureTitle[3]') }}</p>
                                <span>{{ $t('Public.Home.ourCulture.cultureIntro[3]') }}</span>
                            </li>
                            <li>
                                <div><img src="../../public/images/homepage/icon_zhixingli.png" /></div>
                                <p>{{ $t('Public.Home.ourCulture.cultureTitle[4]') }}</p>
                                <span>{{ $t('Public.Home.ourCulture.cultureIntro[4]') }}</span>
                            </li>
                            <li>
                                <div><img src="../../public/images/homepage/icon_leguanjinqu.png" /></div>
                                <p>{{ $t('Public.Home.ourCulture.cultureTitle[5]') }}</p>
                                <span>{{ $t('Public.Home.ourCulture.cultureIntro[5]') }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </transition>
        </div>
        <div class="third1_page">
            <div style="min-height: 2.6rem; margin-bottom: 0.4rem">
                <p class="page_title" style="color: #fff; margin-bottom: 0.4rem">{{ $t('Public.Home.service.services.title') }}</p>
                <div class="second_text" style="color: #fff">{{ $t('Public.Home.service.services.text1') }}</div>
            </div>
            <div class="card_box">
                <template v-for="(cardItem, cardIndex) in cardArr">
                    <div :key="cardIndex" :ref="'card' + cardIndex" class="card" @mouseenter="mouseAction(true, cardIndex)" @mouseleave="(e) => mouseAction(false, cardIndex, e)">
                        <div class="card_top">
                            <img v-if="cardIndex == 0" src="../../public/images/service/service_1.png" />
                            <img v-if="cardIndex == 1" src="../../public/images/service/service_2.png" />
                            <img v-if="cardIndex == 2" src="../../public/images/service/service_3.png" />
                        </div>
                        <div class="hover_content" :ref="'hoverContent' + cardIndex">
                            <h2>{{ $t('Public.Home.service.scopeOfService.list[' + cardIndex + '].title') }}</h2>
                            <div class="option_desc" v-if="$i18n.locale == 'CN'">{{ $t('Public.Home.service.scopeOfService.list[' + cardIndex + '].subTitle') }}</div>
                            <div class="solution-line"></div>
                            <div class="subscript_box" v-for="(titleItem, titleIndex) in cardArr" :key="titleIndex">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 12 9"
                                    height="9"
                                    width="12"
                                    class="hc_Svg"
                                    icon='<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 9" height="9" width="12"><path fill="#3370FF" d="M0.667783 3.61082C0.793006 3.48292 0.962822 3.41107 1.13989 3.41107C1.31695 3.41107 1.48677 3.48292 1.61199 3.61082L4.33077 6.38811L10.388 0.199743C10.5132 0.0718476 10.683 0 10.8601 0C11.0372 0 11.207 0.0718476 11.3322 0.199743L11.8043 0.682069C11.8664 0.745423 11.9156 0.820641 11.9491 0.90343C11.9827 0.986218 12 1.07495 12 1.16457C12 1.25418 11.9827 1.34291 11.9491 1.4257C11.9156 1.50849 11.8664 1.58371 11.8043 1.64706L4.80288 8.80009C4.74087 8.86346 4.66724 8.91374 4.58621 8.94804C4.50517 8.98235 4.41832 9 4.3306 9C4.24289 9 4.15604 8.98235 4.075 8.94804C3.99397 8.91374 3.92034 8.86346 3.85833 8.80009L0.195678 5.05814C0.133642 4.99478 0.084431 4.91956 0.0508561 4.83678C0.0172812 4.75399 0 4.66525 0 4.57564C0 4.48603 0.0172812 4.39729 0.0508561 4.3145C0.084431 4.23172 0.133642 4.1565 0.195678 4.09314L0.667783 3.61082Z"></path></svg>'
                                >
                                    <path
                                        fill="#3370FF"
                                        d="M0.667783 3.61082C0.793006 3.48292 0.962822 3.41107 1.13989 3.41107C1.31695 3.41107 1.48677 3.48292 1.61199 3.61082L4.33077 6.38811L10.388 0.199743C10.5132 0.0718476 10.683 0 10.8601 0C11.0372 0 11.207 0.0718476 11.3322 0.199743L11.8043 0.682069C11.8664 0.745423 11.9156 0.820641 11.9491 0.90343C11.9827 0.986218 12 1.07495 12 1.16457C12 1.25418 11.9827 1.34291 11.9491 1.4257C11.9156 1.50849 11.8664 1.58371 11.8043 1.64706L4.80288 8.80009C4.74087 8.86346 4.66724 8.91374 4.58621 8.94804C4.50517 8.98235 4.41832 9 4.3306 9C4.24289 9 4.15604 8.98235 4.075 8.94804C3.99397 8.91374 3.92034 8.86346 3.85833 8.80009L0.195678 5.05814C0.133642 4.99478 0.084431 4.91956 0.0508561 4.83678C0.0172812 4.75399 0 4.66525 0 4.57564C0 4.48603 0.0172812 4.39729 0.0508561 4.3145C0.084431 4.23172 0.133642 4.1565 0.195678 4.09314L0.667783 3.61082Z"
                                    ></path>
                                </svg>
                                <span>{{ $t('Public.Home.service.scopeOfService.list[' + cardIndex + '].text' + (titleIndex + 1)) }}</span>
                            </div>
                            <div :class="{ box__BUUrV: 1, box__BUUrVEN: $i18n.locale == 'EN' }" @click="handleRoute(cardIndex)">
                                <span>{{ $t('Public.Home.service.scopeOfService.list[' + cardIndex + '].look') }}</span>
                                <img src="../../public/images/service/jiantou.png" loading="eager" />
                            </div>
                        </div>
                        <a class="click_region" :ref="'clickRegion' + cardIndex">
                            <h2 style="margin-top: 4px">{{ $t('Public.Home.service.scopeOfService.list[' + cardIndex + '].title') }}</h2>
                            <span class="option_desc">{{ $t('Public.Home.service.scopeOfService.list[' + cardIndex + '].subTitle') }}</span>
                            <div class="link_btn">
                                <img src="../../public/images/service/jiantou.png" loading="eager" />
                            </div>
                        </a>
                    </div>
                </template>
            </div>
        </div>
        <div class="fourth_page">
            <transition name="slide-fade">
                <div v-if="fourthPageShow">
                    <p class="page_title">{{ $t('Public.Home.quote.title') }}</p>
                    <p class="page_sign">{{ $t('Public.Home.quote.sign') }}</p>
                </div>
            </transition>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';

export default {
    name: 'Home',
    components: {
        MyHeader,
        MyFooter
    },
    // todo   改动历程搜 "历程每次"
    data() {
        return {
            courseData: [], //展示的历程数据，五个
            courseBaseData: [], //处理过的历程数据，所有的
            show: false,
            secondPageShow: false,
            thirdPageShow: false,
            fourthPageShow: false,
            clientHeight: 0,
            devicePixelRatio: 0,
            isMousemove: false,
            isVideoLoaded: false,
            activeIndex: null,
            cardArr: [{}, {}, {}]
        };
    },
    methods: {
        handleRoute(index) {
            let urlObj = {
                0: '/UBIService',
                1: '/ControlService',
                2: '/SecurityService'
            };
            this.$router.push(urlObj[index]);
        },
        mouseAction(enterFlag, index, event) {
            if (enterFlag) {
                this.$refs['card' + index][0].classList.add('card' + (index + 1));
                // 展开动画
                setTimeout(() => {
                    this.$refs['hoverContent' + index][0].classList.add('show_content');
                }, 30);
                setTimeout(() => {
                    this.$refs['clickRegion' + index][0].className = 'click_region1';
                }, 40);
            } else {
                this.$refs['card' + index][0].className = 'card';
                this.$refs['hoverContent' + index][0].className = 'hover_content';
                // 收起动画
                this.$refs['clickRegion' + index][0].className = 'click_region';
            }
        },
        scrollToSecondpage() {
            const offset = 50;
            var element = document.querySelector('.second_page');

            // 获取目标元素相对于视口的位置
            const elementRect = element.getBoundingClientRect();
            const elementTop = elementRect.top + window.pageYOffset;

            // 计算带偏移的目标位置
            const offsetPosition = elementTop - offset;

            // 使用window.scrollTo方法实现带偏移量的滚动
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        },

        videoLoaded() {
            this.isVideoLoaded = true;
        },

        //点击圆点
        clickRound(item) {
            if (this.isMousemove) {
                return false;
            }
            if (!item.checked) {
                this.courseData.forEach((val, key) => {
                    val.checked = false;
                });
                item.checked = true;
            }
        },
        windowScroll(e) {
            // 获取视窗高度
            var domHight = document.body.offsetHeight;
            //获取浏览器视口的高度
            var innerHeight = window.innerHeight;
            // dom滚动位置
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            var secondPage = document.querySelector('.second_page');
            var fourthPage = document.querySelector('.fourth_page');

            if (this.$i18n.locale == 'EN') {
                var thirdPage = document.querySelector('.third_page');
                if (scrollTop >= secondPage.offsetHeight + thirdPage.offsetHeight / 2 && scrollTop < innerHeight + secondPage.offsetHeight + thirdPage.offsetHeight / 2) {
                    this.thirdPageShow = true;
                }
                if (scrollTop <= secondPage.offsetHeight || scrollTop >= innerHeight + secondPage.offsetHeight + thirdPage.offsetHeight) {
                    this.thirdPageShow = false;
                }
            }
            // 显示文字
            if (scrollTop <= innerHeight / 2) {
                this.show = true;
            }
            if (scrollTop >= secondPage.offsetHeight / 2 && scrollTop <= innerHeight + secondPage.offsetHeight / 2) {
                this.secondPageShow = true;
            }
            if (scrollTop >= fourthPage.offsetHeight / 2 && scrollTop + innerHeight > fourthPage.offsetTop + fourthPage.offsetHeight / 4) {
                this.fourthPageShow = true;
            }

            //隐藏文字
            if (scrollTop >= innerHeight * 0.67) {
                this.show = false;
            }
            if (scrollTop <= secondPage.offsetHeight / 5 || scrollTop >= secondPage.offsetHeight + innerHeight) {
                this.secondPageShow = false;
            }
            if (scrollTop + innerHeight < fourthPage.offsetTop) {
                this.fourthPageShow = false;
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.windowScroll);
    },
    mounted() {
        window.addEventListener('scroll', this.windowScroll);
        var _this = this;
        setTimeout(() => {
            this.show = true;
        }, 300);
    },
    activated() {}
};
</script>
<style scoped>
.wrap_en .course_dics {
    font-size: 0.18rem;
}
.slide-fade-leave-active {
    transition: all 1.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(0.6rem);
    opacity: 0;
}
.frist_page {
    width: 100%;
    position: relative;
    /* background: url(../../public/images/homepage/banner.jpg) no-repeat; */
    background-size: cover;
    background-color: #0e253e;
    background-position: center;
    height: 100vh;
}
.frist_gif {
    width: 9.6rem;
    height: 7.52rem;
    position: absolute;
    top: 2rem;
    right: 0;
    z-index: 50;
}
.banner_text {
    position: absolute;
    top: 43%;
    left: 0;
    z-index: 50;
    transform: translate(0, -50%);
    width: 100%;
    font-size: 0.4rem;
    line-height: 1;
    text-align: center;
}
.banner_text p {
    font-size: 0.75rem;
    line-height: 1.39;
    margin-bottom: 0.22rem;
    text-shadow: 0px 0px 24px rgba(255, 255, 255, 0.75);
    margin-right: 3.7rem;
    margin-left: 3.7rem;
}

.page_slogan {
    font-style: italic;
    padding-bottom: 0.2rem;
    position: absolute;
    bottom: 1.5rem;
    width: 100%;
    text-align: center;
    font-size: 0.5rem;
    color: #028888;
    text-shadow: 0px 0px 12px #028888;
}

.frist_page video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video_2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
}
.second_page {
    background: #010d16;
    padding: 0.8rem 2.2rem 1.2rem;
    font-size: 0.2rem;
    color: #fff;
    /* min-height: 10rem; */
}
.second_img {
    width: 100%;
    margin-top: 0.5rem;
}
.page_title {
    font-size: 0.32rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.9rem;
    color: #fff;
}
.page_sign {
    text-align: center;
    font-size: 0.3rem;
}
.fourth_page .page_title {
    font-size: 0.9rem;
    font-weight: normal;
    margin-right: 2rem;
    margin-left: 2rem;
}
.second_page .page_title {
    margin-bottom: 0.5rem;
}
.second_text {
    text-indent: 2em;
    line-height: 2;
    text-align: justify;
    text-justify: inter-ideograph;
}
.wrap_en .second_text {
    text-align: justify;
}
.fourth_page {
    background: url(../../public/images/homepage/course_bg.jpg) no-repeat;
    background-size: 100% 100%;
    height: 8rem;
    color: rgba(255, 255, 255, 0.6);
    padding-top: 1.3rem;
    margin-bottom: -1px;
}
.course {
    position: relative;
    width: 100%;
    min-height: 7.27rem;
}
.course_line {
    width: 100%;
    height: 2.19rem;
    background: url(../../public/images/homepage/course_line.png) no-repeat;
    background-size: 100% 100%;
}
.fourth_line {
    position: absolute;
    top: 1.25rem;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 1px;
    background-color: #f4f3f3;
}
.more_left,
.more_right {
    position: absolute;
    top: 3.17rem;
    left: 10%;
    z-index: 20;
    width: 0.37rem;
    height: 0.6rem;
    cursor: pointer;
    z-index: 99;
}
.more_right {
    right: 10%;
    left: auto;
}
.third_page {
    width: 100%;
    height: 8rem;
    background: url(../../public/images/homepage/qiyewenhua.png) no-repeat;
    background-size: 100% 100%;
    padding-top: 1rem;
    margin-bottom: -1px;
}
.third1_page {
    /* background: #fff; */
    background: rgb(7, 29, 52);
    background: rgb(15, 27, 39);
    /* background: rgb(2, 32, 78); */
    padding: 0.8rem 2.6rem 1.2rem;
    font-size: 0.2rem;
    color: #333;
}
.third1_page .card_box {
    display: flex;
    justify-content: space-between;
}
.third1_page .card_box .card {
    overflow: hidden;
    box-sizing: border-box;
    height: 4.5rem;
    min-height: 250px;
    width: 28%;
    border-radius: 15px;
    position: relative;
    display: inline-block;
}
.card .card_top img {
    margin-top: -0.8rem;
}
.third1_page .card_box .card .click_region {
    padding: 0.25rem 0.38rem 0.34rem 0.36rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    transition: height 0.2s ease-in-out;
    background: #f6f6fb;
    overflow: hidden;
    box-sizing: border-box;
    height: 2.66rem;
    cursor: default;
    z-index: 1;
    cursor: pointer;
    transition: height 0.5s ease;
}
.click_region1 {
    height: 4.5rem;
}

.click_region h2 {
    color: #333;
}
.click_region .option_desc {
    font-weight: 500;
    color: #646a73;
    margin-top: 17px;
    display: inline-block;
}
.click_region .link_btn {
    max-height: 0.36rem;
    width: 0.15rem;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    cursor: pointer;
    position: absolute;
    bottom: 0.25rem;
}
.link_btn img {
    width: 17px;
    height: 14px;
}
.hover_content {
    background: url(../../public/images/service/bgc.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    padding: 0.1rem 0.3rem;
    padding-top: 0.3rem;
    height: 4.5rem;
    min-height: 250px;
    width: 100%;
    top: 140px;
    display: none;
    transition: top 0.5s ease;
}
.show_content {
    top: 0;
}

.card1 .click_region,
.card2 .click_region,
.card3 .click_region {
    display: none;
}
.card1 .hover_content,
.card2 .hover_content,
.card3 .hover_content {
    display: initial;
}
.hover_content .option_desc {
    margin-top: 10px;
}
.hover_content .box__BUUrV {
    width: 157px;
    height: 34px;
    line-height: 34px;
    padding-left: 40px;
    border-radius: 100px;
    border: 1px solid #646a73;
    color: #1f2329;
    position: relative;
    cursor: pointer;
    font-size: 14px;
}
.hover_content .box__BUUrVEN {
    padding-left: 24px;
}
.hover_content .box__BUUrVEN img {
    right: 15px !important;
}
.hover_content .box__BUUrV {
    position: absolute;
    bottom: 0.2rem;
}
.hover_content .box__BUUrV img {
    width: 15px;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
}
.hover_content .solution-line {
    height: 1px;
    opacity: 0.5;
    background: linear-gradient(90deg, #41a4ff 0, #638fff 50.52%, #9554ff 100%);
    margin-top: 23px;
    margin-bottom: 23px;
}
.hover_content .subscript_box {
    margin-bottom: 10px;
}
.hover_content .subscript_box:last-child {
    margin-bottom: 0;
}
.hover_content .subscript_box svg {
    margin-right: 10px;
}

.culture_wall {
    position: relative;
    width: 12rem;
    height: 4rem;
    margin: 1.08rem auto 0;
}
.line1 {
    width: 10.6rem;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 2rem;
    left: 0.95rem;
    z-index: 10;
}
.wrap_en .line1 {
    width: 12rem;
    left: 0;
}
.line2,
.line3 {
    width: 1px;
    height: 3.28rem;
    background-color: #fff;
    position: absolute;
    top: 0.36rem;
    left: 4rem;
    z-index: 10;
}
.line3 {
    left: 8rem;
}
.culture_wall ul {
    width: 101%; /* 100% not work for FF*/
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.culture_wall ul.active {
    padding-top: 0.2rem;
}
.culture_wall ul li {
    width: 4rem;
    text-align: center;
    font-size: 0.18rem;
    margin-bottom: 0.9rem;
}
.culture_wall ul li div {
    width: 0.64rem;
    margin: 0 auto;
}
.culture_wall ul li p {
    font-size: 0.3rem;
    margin: 0.1rem 0;
}
.culture_wall li span {
    height: 0.18rem;
    width: 100%;
    display: inline-block;
}

@keyframes rotate-and-fade {
    0% {
        transform: rotateY(0deg) translateZ(100px) scale(1);
        text-shadow: 0 0 8px #0ff, 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #1bf, 0 0 40px #1bf, 0 0 50px #1bf, 0 0 60px #1bf, 0 0 70px #2bf;
    }
    25% {
        transform: rotateY(90deg) translateZ(100px) scale(0.75);
    }
    50% {
        transform: rotateY(180deg) translateZ(100px) scale(0.5);
        text-shadow: 0 0 5px #0ff, 0 0 15px #0ff, 0 0 25px #1bf, 0 0 35px #1bf, 0 0 45px #2bf, 0 0 55px #2bf, 0 0 65px #2bf, 0 0 75px #3cf;
    }
    75% {
        transform: rotateY(270deg) translateZ(100px) scale(0.75);
    }
    100% {
        transform: rotateY(360deg) translateZ(100px) scale(1);
        text-shadow: 0 0 8px #0ff, 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #1bf, 0 0 40px #1bf, 0 0 50px #1bf, 0 0 60px #1bf, 0 0 70px #2bf;
    }
}

@keyframes opacity-fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.circle-container {
    perspective: 500px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    color: #4bc4ff;
}

.circle-text {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
}

.text-base {
    position: absolute;
    top: 35%;
    left: 46%;
    transform-origin: center center;
    transform: translateX(-50%) translateZ(150px);
    font-size: 0.2rem;
    animation: rotate-and-fade 15s infinite linear, opacity-fade 1s infinite linear;
}

.text1 {
    top: 52% !important;
    animation-delay: -1.33s;
    opacity: 0;
}

.text2 {
    top: 45% !important;
    animation-delay: -4.33s;
    opacity: 0;
}

.text3 {
    top: 40% !important;
    animation-delay: -5.33s;
    opacity: 0;
}

.text4 {
    top: 32% !important;
    animation-delay: -2.66s;
    opacity: 0;
}

.text5 {
    top: 30% !important;
    animation-delay: -6.66s;
    opacity: 0;
}

@keyframes scrollBottom {
    form {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
}

.scrollBottom {
    width: 100%;
    text-align: center;
    font-size: 0.12rem;
    color: #fff;
    position: absolute;
    bottom: 0.1rem;
    cursor: pointer;
}

.scrollBottom i {
    display: block;
    width: 15px;
    height: 15px;
    margin: 0 auto;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
    animation: scrollBottom 1.5s linear infinite;
}

.scrollBottom i:nth-child(2) {
    animation-delay: 0.3s;
}

.scrollBottom span {
    display: inline-block;
    margin-top: 20px;
}
</style>
