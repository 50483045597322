import Vue from 'vue'
import LanguageData from './components/language/index'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
Vue.config.productionTip = false
var baseURL=window.location.protocol+'//'+window.location.host+"/api"
// var baseURL="https://official.sigreal.com.cn/api"
// var baseURL="http://192.168.1.118:8080/api"
Vue.prototype.$axios=axios;
Vue.prototype.instance=Vue.prototype.$axios.create({
		  baseURL
})
Vue.use(ElementUI);
Vue.use(VueI18n);
const i18n=new VueI18n({
    // locale:localStorage.getItem('languageSet')||'CN',   //从localStorage里获取用户中英文选择，没有则默认中文
    locale:getLang(),   
	messages:{
		'CN': LanguageData.CN,
		'EN': LanguageData.EN
	}
})
// console.log(i18n.locale);
new Vue({
  router,i18n,
  render: h => h(App)
}).$mount('#app')
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  document.documentElement.scrollTop = 0
  next()
})
var fontSize=0;
var fontRem=0;
// 获取 URL 参数
Vue.prototype.getQueryString=function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var href = window.location.href;
    href=decodeURI(href);
    var r = href.substr(href.indexOf("?") + 1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
changeFrameHeight();
//设置字体自适应  默认1920*1080 为100px
function changeFrameHeight(ratio){
    (function (doc, win) {
        var docEl = doc.documentElement,
            resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
            recalc = function () {
                // var clientWidth = docEl.clientWidth;
                var clientWidth = window.innerWidth;
                if (!clientWidth) return;
                docEl.style.fontSize = 100/6 * (clientWidth / 320) + 'px';
                fontRem=parseFloat(document.documentElement.style.fontSize);
                fontSize=100/6 * (clientWidth / 320);
				// var clientHeight = docEl.clientHeight;
				// if (!clientHeight) return;
				// docEl.style.fontSize = 100/6 * (clientHeight / 180) + 'px';
				// fontRem=parseFloat(document.documentElement.style.fontSize);
				// fontSize=100/6 * (clientWidth / 320);
            };
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener('DOMContentLoaded', recalc, false);
    })(document, window);
}
//获取浏览器语言
function getLang(){
    let type=navigator.appName;
    let lang='';
    if (type=="Netscape") {
        lang = navigator.language 
    }else{
        lang = navigator.userLanguage 
    }
    if(lang=='zh-CN'){
        return 'CN' 
    }else{
        return 'EN'
    }
}
// 时间格式化
Vue.prototype.dateFormatting=function(date,fmt) {
    var o = {
        "M+" : date.getMonth()+1,                 //月份
        "d+" : date.getDate(),                    //日
        "h+" : date.getHours(),                   //小时
        "m+" : date.getMinutes(),                 //分
        "s+" : date.getSeconds(),                 //秒
        "q+" : Math.floor((date.getMonth()+3)/3), //季度
        "S"  : date.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));
    for(var k in o)
        if(new RegExp("("+ k +")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    return fmt;
}