const info={
    CN: {
        contactUs: "联系我们",
        followUs: "关注我们",
        addr: "地址：北京市朝阳区光华路SOHO",
		tel:"电话：010-87856177",
		email:"邮箱：contact@honglingtech.com.cn"
    },
    EN: {
        contactUs: "CONTACT US",
        followUs: "FOLLOW US",
        addr: "Our Office：SOHO, Guanghua Road, Chaoyang District, Beijing",
        tel:"TEL：010-87856177",
        email:"E-MAIL：contact@honglingtech.com.cn"
    }
}
export default info