const info={
    CN: {  //中文翻译
		banner:{
			title:'新能源汽车后市场解决方案',
			text:'为新能源主机厂提供创新化的保险产品、增加客户黏性的运营工具，同时引导新能源车主安全驾驶；为换电站提供创新定制化智能充换电策略，降本提效。',
		},
		solutions:{
			title:'智能充换电解决方案',
			intro:'借助智能算法模型对新能源车辆充换电行为进行预测，为充换电站及车辆制定动态高效的充换电策略，帮助车企及服务商打造数字化、智能化的充换电站管理体系，优化运营成本，提高服务质量。',
			list:[
				{
					title:'换电意愿评估模型',
					title2:''
				},
				{
					title:'充换电推荐算法',
					title2:''
				},
				{
					title:'充换电站智能排序',
					title2:''
				},
				{
					title:'新能源动力电池全生命周期管理平台',
					title2:''
				}
			]
		},
		extended:{
			title:'新能源汽车后市场创新保险服务',
			service:{
				battery1:'新能源汽车电池“电池无忧”：',
				battery2:'缓解新能源车主对于新能源汽车电池衰减、二手车残值低等的顾虑;',
				warranty1:'新能源汽车延保“延保无忧”：',
				warranty2:'延保范围覆盖核心“三电”及其他整车各零部件;',
				warranty3:'新能源汽车二手车保险：',
				warranty4:'有保障，有服务，助力新能源汽车二手车市场发展。'
			}
		}
    },
    EN: {  //英文翻译 
		banner:{
			title:'New Energy Vehicle Services',
			text:"We create powerful insurance products for automobile manufacturers, guiding their drivers to drive safely. We also provide automobile manufacturers with operational solutions for stimulating customer engagement. We provide swapping stations with customized charging and battery swapping strategies to reduce costs.  "
		},
		solutions:{
			title:'Intelligent Charging and Battery Swapping Solutions',
			intro:'We predict the charging and battery swapping behavior of new energy vehicles with the help of intelligent algorithm models. Our customized charging and battery swapping solutions help battery swapping stations reduce their operating costs. We also help them develop technology platforms to improve customer satisfaction.',
			list:[
				{
					title:'Prediction Model of Drivers’ Will for Battery Swapping',
					title2:''
				},
				{
					title:'Prediction Model of Battery Demand for the Battery Swapping Station ',
					title2:''
				},
				{
					title:'Intelligent Charging and Swapping Solutions for the Battery Swapping Station',
					title2:''
				},
				{
					title:'Management Platform for Batteries of New Energy Vehicles',
					title2:''
				}
			]
		},
		extended:{
			title:'New Energy Vehicle Innovative Insurance Services',
			intro:'',
			service:{
				battery1:'New energy vehicle "battery worry-free" service:',
				battery2:'This service addresses the problem of battery attenuation and low residual value in used vehicles.',
				warranty1:'New energy vehicle "warranty extension worry-free" service:',
				warranty2:'This product covers "Battery, Motor, and Electric Control System" and other parts of the vehicle.',
				warranty3:'Auto Insurance Services for Used New Energy Vehicles',
				warranty4:'We evaluate the condition of used new energy vehicles and provide accurate prices estimates. Our aim is to facilitate the healthy development of the used market of new energy vehicles.',
			},
		}
    }
}
export default info  //需要暴露出去  