const info = {
    CN: {
        //中文翻译
        menus: ["首页", "服务", "公司动态", "加入我们"],
        menus2: [
            "基于多模态大模型的车联网服务",
            "新能源汽车后市场解决方案",
            "营运车队风险管控服务",
            "新兴行业安全保障服务",
        ],
    },
    EN: {
        //英文翻译
        menus: ["HOME", "SERVICES", "NEWS", "JOIN US"],
        menus2: [
            "Multimodal Large Model-Based IoV Services",
            "New Energy Vehicle Services",
            "Fleet Risk Control",
            "Logistics/Delivery Industry Safety and Security Services",
        ],
    },
};
export default info; //需要暴露出去
