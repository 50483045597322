const info = {
    CN: {
        //中文翻译
        services: {
            title: "我们的服务",
            text1: "宏瓴科技致力于将人工智能技术应用在交通运输行业、工业、农业等多维度行业场景，自主研发创新的个体（包括但不限于人、机器、动植物等）行为风险评估模型与风险控制方案，不仅深入探索个体行为模式，更锁定潜在风险点，实现风险成本的个体化精确度量。",
            text2: "在数字化风控、智能出行、绿色能源后市场以及即时配送等新兴领域，提供一站式的风控技术解决方案，通过结合人工智能算法与前沿的产品创新设计理念，优化现有风控流程，催生数智化风控领域新生态，构建行业专属算法大模型及全面风险管控。",
        },
        scopeOfService: {
            title: "服务类型",
            list: [
                {
                    title: "AI+主机厂",
                    small_title1: "保险公司定制化车联网服务",
                    small_title2: "汽车主机厂数智化保险服务",
                    text1: "宏瓴科技提供“数据采集与处理—客户运营—风险管控”全产业链解决方案，助力主机厂降低车辆风险成本，提升品牌价值。",
                    text2: "通过刻画智能车主全息画像、风险事件实时提醒，引导车主改善驾驶习惯，降低交通事故发生概率。",
                    text3: "通过为车主创新产品设计与推荐，丰富主机厂服务生态圈。如智能免查勘服务，节省车主时间，提高车主体验。",
                    look: "查看详情",
                },
                {
                    title: "AI+出行平台/商用车平台",
                    text1: "宏瓴科技助力出行平台解决运营管理痛点，提供车队风险管理的闭环解决方案，降低运营成本。",
                    text2: "基于物联网大数据和AI模型，刻画驾驶员风险画像，分析司机驾驶行为，为出行平台提供司机培训和奖惩规范等服务及运营手段，提升安全驾驶水平。",
                    text3: "通过可视化平台实时复现车辆行程和轨迹，精确识别异常行为，自动提醒干预，降低交通事故发生概率。",
                    text4: "",
                    look: "查看详情",
                },
                {
                    title: "AI+物流配送平台",
                    text1: "宏瓴科技通过对物流/配送员驾驶行为数据分析建模，形成专属画像，为物流/配送行业搭建风险管理平台，提供安全保障。",
                    text2: "基于全栈式风险评估管理系统整合多模态数据，识别物流/配送人员面临的风险因素，通过风险预测和提前干预助力安全作业，降低风险事件发生概率。",
                    text3: "风险事件发生后，通过深度学习等AI技术监控欺诈风险，锁定欺诈事件，为降赔减损提供技术支持。",
                    look: "查看详情",
                },
                // ,
                // {
                //     title: "新兴行业安全保障服务",
                //     text: "为新兴行业外卖配送行业提供一站式风险管控解决方案，降低配送过程风险，实现运营成本有效管控。运用深度神经网络、弱监督学习等技术，深度分析配送员的配送行为风险，形成配送人员画像，通过筛选、监管高风险配送行为，配合配送平台引导配送人员主动合规配送，降低配送过程风险，降低保险出险率及赔付深度，最终实现降低运营成本。",
                //     look: "查看详情",
                // },
            ],
        },
    },
    EN: {
        //英文翻译
        services: {
            title: "Services",
            text1: "SiGREAL Tech is committed to applying artificial intelligence technology across diverse industry sectors, including transportation, industrial, and agricultural scenarios. The company independently develops innovative behavior risk assessment models and risk control solutions for various entities (including, but not limited to, humans, machines, and living organisms). These solutions delve deep into behavior patterns, pinpoint potential risk factors, and enable precise, individualized measurement of risk costs.",
            text2: `In the emerging fields of digital risk management, smart mobility, green energy aftermarket, and instant delivery, we offer one-stop risk management technology solutions. By integrating artificial intelligence algorithms with advanced product innovation and design philosophies, we optimize the existing risk management processes. This approach cultivates a new ecosystem in the digital intelligence risk control sector, establishing industry-specific algorithmic big models and comprehensive risk monitoring and control systems.`,
        },
        scopeOfService: {
            title: "Scope Of Services",
            list: [
                {
                    title: "AI+ Automotive OEM",
                    small_title1: "",
                    small_title2: "",
                    text1: 'SiGREAL Tech offers a comprehensive "data collection and processing—customer operations—risk management" solution across the entire industry chain, helping OEMs reduce vehicle risk costs and enhance brand value.',
                    text2: "By creating a comprehensive profile of intelligent vehicle owners and providing real-time alerts for risk events, SiGREALTech guides drivers to improve their driving habits, ultimately reducing the likelihood of traffic accidents.",
                    text3: "By offering innovative product designs and recommendations for vehicle owners, SiGREALTech enriches the service ecosystem of OEMs. For instance, services like intelligent claim-free inspections save time for vehicle owners and enhance their overall experience.",
                    look: "LEARN MORE HERE",
                },
                {
                    title: "AI + Mobility Platforms/Commercial Vehicle Platforms",
                    text1: "SiGREALTech helps mobility platforms address operational management challenges by providing a closed-loop fleet risk management solution, effectively reducing operational costs.",
                    text2: "Leveraging IoT big data and AI models, SiGREALTech creates detailed risk profiles for drivers by analyzing their driving behaviors. This enables mobility platforms to offer driver training, establish reward and penalty systems, and implement other operational strategies, ultimately enhancing safe driving practices.",
                    text3: "Through a visualization platform, SiGREALTech enables real-time replication of vehicle trips and trajectories, accurately identifying abnormal behaviors and automatically triggering alerts for intervention. This reduces the likelihood of traffic accidents.",
                    text4: "",
                    look: "LEARN MORE HERE",
                },
                {
                    title: "AI + Logistics/Delivery Platforms",
                    text1: "SiGREALTech analyzes and models driving behavior data of logistics and delivery personnel to create tailored profiles, thereby building a risk management platform for the logistics and delivery industry that enhances safety and security.",
                    text2: "Based on a full-stack risk assessment and management system, SiGREALTech identifies risk factors faced by logistics and delivery personnel. By leveraging risk prediction and proactive intervention, the company supports safe operations and reduces the likelihood of risk events.",
                    text3: "After a risk event occurs, SiGREALTech utilizes AI technologies such as deep learning to monitor and detect fraud risks, pinpoint fraudulent activities, and provide technical support for reducing claims and minimizing losses.",
                    look: "LEARN MORE HERE",
                },
                // {
                //     title: "Intelligent insurance service platform",
                //     text: "Combine traditional auto insurance issuing with mature User Behavior Insurance experience to not only meet the issuing needs of traditional auto insurance, but also realize the function of User Behavior Insurance model, support the development and sale of innovative insurance products, fully enable insurance operation, and help customers build a brand image of ecological operation.",
                //     look: "LEARN MORE HERE",
                // },
            ],
        },
    },
};
export default info; //需要暴露出去
