<template>
    <div class="footer">
		<div class="section">
			<div class="footer_content">
				<div class="footer_content_left">
					<p>{{$t('Public.Footer.contactUs')}}</p>
					<ul>
						<li>
							<div><img src="../../public/images/daohangdizhi.png" ></div>
							<span>{{$t('Public.Footer.addr')}}</span>
						</li>
						<li>
							<div><img src="../../public/images/dianhua.png" ></div>
							<span>{{$t('Public.Footer.tel')}}</span>
						</li>
						<li>
							<div><img src="../../public/images/youxiang.png" ></div>
							<span>{{$t('Public.Footer.email')}}</span>
						</li>
					</ul>
				</div>
				<div class="footer_content_right">
					<p>{{$t('Public.Footer.followUs')}}</p>
					<div><img src="../../public/images/ercode_h.png" ></div>
				</div>
			</div>
			<div class="filing">
				Copyright @2024 北京宏瓴科技发展有限公司 
				<span onclick="window.open('https://beian.miit.gov.cn')">{{ICP}}</span>
				京公网安备 11011302001560号
			</div>
		</div>
    </div>
</template>

<script>
export default {
	name: 'MyFooter',
    props: {
		msg: String
	},
	data(){
		return{
			activeIndex: '/',
			ICP:'京ICP备18036154号-1'
		}
	},
	methods:{
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
		},
	},
	mounted() {
		var hostname=window.location.hostname;
		if(hostname=="www.lvpaiche.com"){
			this.ICP="京ICP备18036154号-2";
		}else if(hostname=="www.honglingtech.com.cn"){
			this.ICP="京ICP备18036154号-1";
		}else if(hostname=="www.sigreal.cn"){
			this.ICP="京ICP备18036154号-3";
		}else if(hostname=="www.sigreal.com.cn"){
			this.ICP="京ICP备18036154号-4";
		}else if(hostname=="www.honglingtech.com"){
			this.ICP="京ICP备18036154号-5";
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.footer{
		width: 100%;
		min-height: 2.6rem;
		background: #222222;
		padding:0.39rem 0 0.24rem;
	}
	.section{
		width:11.4rem;
		margin:0 auto;
	}
	.footer_content{
		display: flex;
		justify-content: space-between;
		padding-bottom: 0.2rem;
		border-bottom: 1px solid rgba(151, 151, 151, 0.3);
	}
	.footer_content_left p,.footer_content_right p{
		font-size:0.18rem;
		color:#ccc;
	}
	.footer_content_right p{
		text-align: center;
	}
	.filing{
		text-align: center;
		color: #666;
		margin-top: 0.22rem;
	}
	.filing span{
		cursor:pointer;
	}
	.footer_content_left ul{
		margin-top: 0.1rem;
	}
	.footer_content_left li{
		font-size: 0.16rem;
		color: #b6b6b6;
		/* line-height: 1.6; */
		display: flex;
		align-items: center;
		margin-bottom: 0.04rem;
	}
	.footer_content_left li div{
		width:0.2rem ;
		margin-right: 0.08rem;
	}
	.footer_content_right div{
		width: 0.8rem;
		margin: 0.2rem auto 0;
	}
</style>
