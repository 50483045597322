<template>
    <div class="header" :class="{ white_bg: whiteBg, wrap_en: $i18n.locale == 'EN' }" @mouseover="whiteBg = true" @mouseleave="windowScroll">
        <div class="section">
            <div class="logo" @click="handleLogo">
                <img v-show="!whiteBg" src="../../public/images/logo.png" />
                <img v-show="whiteBg" src="../../public/images/logo2.png" />
            </div>
            <div class="flex">
                <el-menu :default-active="$route.path" class="el-menu-demo" active-text-color="#05A3E4" router mode="horizontal" menu-trigger="hover" :background-color="whiteBg ? 'transparent' : 'transparent'" :text-color="whiteBg ? '#333' : '#fff'">
                    <el-menu-item index="/">{{ $t('Public.Header.menus[0]') }}</el-menu-item>
                    <!-- <el-menu-item index="/Service">{{$t('Public.Header.menus[1]')}}</el-menu-item> -->
                    <!-- <el-submenu index="/Service" :class="{ checked_service: $route.name == 'Service' }" @click.native="handleService" :popper-append-to-body="false">
                        <template slot="title">{{ $t('Public.Header.menus[1]') }}</template>
                        <el-menu-item index="UBIService">{{ $t('Public.Header.menus2[0]') }}</el-menu-item>
                        <el-menu-item index="ControlService">{{ $t('Public.Header.menus2[2]') }}</el-menu-item>
                        <el-menu-item index="SecurityService">{{ $t('Public.Header.menus2[3]') }}</el-menu-item>
                    </el-submenu> -->
                    <el-menu-item index="/CompanyDynamic">{{ $t('Public.Header.menus[2]') }}</el-menu-item>
                    <el-menu-item index="/JoinUs">{{ $t('Public.Header.menus[3]') }}</el-menu-item>
                </el-menu>
                <div class="change_language"><span @click="changeLanguage('CN')" :class="{ active: $i18n.locale == 'CN' }">中文</span> / <span @click="changeLanguage('EN')" :class="{ active: $i18n.locale == 'EN' }">EN</span></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyHeader',
    props: {
        msg: String
    },
    data() {
        return {
            activeIndex: '/',
            whiteBg: false
        };
    },
    methods: {
        changeLanguage(sign) {
            if (this.$i18n.locale != sign) {
                this.$i18n.locale = sign;
                // this.$i18n.locale=='CN'?this.$i18n.locale='EN':this.$i18n.locale='CN'   //设置中英文模式
                localStorage.setItem('languageSet', this.$i18n.locale); //将用户设置存储到localStorage以便用户下次打开时使用此设置
                this.$emit('changeLanguage');
            }
        },
        //点击logo
        handleLogo() {
            // if(this.$route.path!='/'){
            this.$router.push({ path: '/', query: { v: Math.random() } });
            // this.$router.push({ name: 'Home', params: { userId: Math.random() }})
            // }
        },
        handleService() {
            if (this.$route.path != '/Service') {
                this.$router.push('/Service');
            }
        },
        windowScroll() {
            var scrollTop = document.documentElement.scrollTop;
            if (scrollTop > 10) {
                this.whiteBg = true;
            } else {
                this.whiteBg = false;
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.windowScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.windowScroll);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all 0.6s;
}
/deep/ .el-menu-demo .el-menu-item {
    transition: all 0.6s;
}
/deep/ .el-menu-demo .el-submenu__title {
    transition: all 0.6s;
}
.white_bg {
    background: #fff;
    /* border-bottom: 1px solid #F4F3F3; */
}
.section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 17.6rem;
    margin: 0 auto;
}
.logo {
    width: 1.3rem;
    cursor: pointer;
    /* margin-right:2.8rem; */
}

.el-menu.el-menu--horizontal {
    border: none;
    /* background:transparent; */
}
.el-menu--horizontal > .el-menu-item,
/deep/.el-menu--horizontal > .el-submenu .el-submenu__title {
    /* height:0.8rem;
		line-height: 0.8rem; */
    height: 60px;
    line-height: 60px;
    /* color: #fff; */
}
/deep/.el-submenu__title i {
    color: #fff;
}
.white_bg /deep/ .el-submenu__title i {
    color: #333;
}
.el-menu-item,
/deep/ .el-submenu__title {
    font-size: 16px;
    padding: 0 30px;
}
/deep/ .el-submenu {
    width: 92px;
}
.wrap_en /deep/ .el-submenu {
    width: 140px;
}
/deep/ .el-submenu__icon-arrow {
    font-size: 0.12rem;
}
.el-menu-item:hover,
/deep/ .el-submenu__title:hover {
    background: transparent !important;
    color: #05a3e4 !important;
}
/deep/ .el-submenu__title i {
    display: none;
}
/deep/ .el-submenu__title:hover i {
    color: #05a3e4 !important;
}
/deep/ .checked_service .el-submenu__title {
    border-bottom: 2px solid #409eff !important;
    color: #05a3e4 !important;
}
.change_language {
    font-size: 16px;
    padding-left: 0.3rem;
    cursor: pointer;
}
.change_language span:hover {
    color: #05a3e4;
}
.change_language .active {
    color: #05a3e4;
}
.white_bg .change_language {
    color: #333;
}
/deep/ .el-menu--popup {
    background-color: #fff !important;
    min-width: 150px;
    padding: 0.12rem 0;
}

/deep/ .el-menu--horizontal .el-menu--popup .el-menu-item {
    color: #333 !important;
    font-size: 0.16rem;
    height: 0.46rem;
    line-height: 0.46rem;
    min-width: 150px;
}
/deep/ .el-menu--horizontal .el-menu--popup .el-menu-item:hover {
    color: #05a3e4 !important;
}
</style>
