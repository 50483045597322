const info = {
    CN: {
        //中文翻译
        card: [
            {
                title: "成就客户",
                text: "才是成就自己",
            },
            {
                title: "务实落地",
                text: "实事求是、真正解决问题",
            },
            {
                title: "开放迭代",
                text: "愿意并尝试新的可能性",
            },
            {
                title: "奋斗者精神",
                text: "追求理想，挑战困难不言弃",
            },
            {
                title: "长期主义",
                text: "坚持，做正确且难的事",
            },
        ],
        modal: {
            title: "职位申请表",
            text1: "请如实填写您的信息，我们将尽快与您联系",
            text2: "",
            placeholder: {
                name: "请填写您的姓名",
                phone: "请填写您的手机号",
            },
            fileName: "上传简历附件",
            button1: "取 消",
            button2: "提 交",
            nameHint: "抱歉，请填写您的姓名",
            phoneHint1: "抱歉，请填写您的手机号",
            phoneHint2: "抱歉，请填写正确的手机号",
            resumeHint: "抱歉，请上传简历",
        },
    },
    EN: {
        //英文翻译
        modal: {
            title: "",
            text1: `Please tell us what do you want to do and leave your email or phone, `,
            text2: "we're lokking forward to contact with you!",
            placeholder: {
                name: "name*",
                phone: "phone*",
            },
            fileName: "My Resum",
            button1: "Cancel",
            button2: "Apply Now",
            nameHint: "Name field is required.",
            phoneHint1: "Phone field is required.",
            phoneHint2: "Please enter the right number.",
            resumeHint: "Resum field is required.",
        },
    },
};
export default info; //需要暴露出去
