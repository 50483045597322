const info = {
    CN: {
        //中文翻译
        banner: {
            // title1:"让人工智能创造更大的价值",
            // title2:"",
            title: "构建世界级交通运输行业大模型",
            content: "Build World-Class Transportation AI Foundation Model",
            slogan: "SiGREAL AI, Be Pathfinder Together",
        },
        aboutUs: {
            title: "公司简介",
            content1:
                "宏瓴科技(SiGREAL Tech)专注于为交通运输行业构建大模型、提供风险减量服务。公司基于AI领域最前沿技术，多模态大模型，强化学习及图神经网络等，自主研发出全栈式风险评估量化平台。目前为行业内多家TOP级客户提供专业服务。",
            content2:
                "核心团队成员来自于清华大学、中科院、波士顿大学、加州大学、苏黎世联邦理工等国内外顶尖院校，曾就职于硅谷知名高科技公司、世界500强集团等核心岗位，长期在国际顶级人工智能会议上发表学术成果，在机器学习、车联网大数据等领域拥有资深的基础研究背景。",
            content3:
                "公司为国家级高新技术企业、北京市 “ 专精特新中小企业、中关村高新技术企业、中关村金种子企业及科技部认证的科技型企业，拥有核心专利及软著百余项。",
        },
        ourCulture: {
            title: "企业文化",
            cultureTitle: [
                "成就客户",
                "务实落地",
                "正直担当",
                "延迟满足感",
                "强执行力",
                "乐观进取",
            ],
            cultureIntro: [
                "才是成就自己",
                "实事求是，真正解决问题",
                "诚实大气，勇于担当，有责任心",
                "习惯做正确且难的事",
                "敢打仗，打胜仗",
                "追求理想，挑战困难，永不放弃",
            ],
        },
        service: {
            services: {
                title: "我们的服务",
                text1: "宏瓴科技致力于将人工智能技术应用在交通运输行业、工业、农业等多维度行业场景，自主研发创新的个体（包括但不限于人、机器、动植物等）行为风险评估模型与风险控制方案，不仅深入探索个体行为模式，更锁定潜在风险点，实现风险成本的个体化精确度量。",
                text2: "在数字化风控、智能出行、绿色能源后市场以及即时配送等新兴领域，提供一站式的风控技术解决方案，通过结合人工智能算法与前沿的产品创新设计理念，优化现有风控流程，催生数智化风控领域新生态，构建行业专属算法大模型及全面风险管控。",
            },
            scopeOfService: {
                title: "服务类型",
                list: [
                    {
                        title: "AI+主机厂",
                        subTitle:
                            "“数据采集与处理—客户运营—风险管控”全产业链解决方案",
                        text1: "刻画车主全息画像",
                        text2: "降低车辆风险成本",
                        text3: "丰富车主服务生态圈",
                        look: "查看详情",
                    },
                    {
                        title: "AI+出行/商用车平台",
                        subTitle:
                            "车队风险管理的闭环解决方案，降低车队运营成本",
                        text1: "刻画驾驶员风险画像",
                        text2: "复现车辆行程和轨迹",
                        text3: "提供车队安全驾驶运营方案",
                        text4: "",
                        look: "查看详情",
                    },
                    {
                        title: "AI+物流配送平台",
                        subTitle:
                            "全栈式风险评估管理平台，为新兴行业提供安全保障。",
                        text1: "风险主体和风险事件识别",
                        text2: "风险预警和提前干预",
                        text3: "识别欺诈风险、降赔减损",
                        look: "查看详情",
                    },
                ],
            },
        },
        quote: {
            title: "“AI has the potential to be one of the most important and beneficial technologies ever invented.”",
            sign: "DEMIS HASSABIS, CO-FOUNDER AND CEO, GOOGLE DEEPMIND",
        },
    },
    EN: {
        //英文翻译
        banner: {
            title: "Build World-Class Transportation AI Foundation Model",
            content: "",
            slogan: "SiGREAL AI, Be Pathfinder Together",
        },
        aboutUs: {
            title: "About Us",
            content1:
                "SiGREAL Tech specializes in developing large-scale models and providing risk mitigation services for the transportation industry. Leveraging cutting-edge AI technologies, including multimodal large models, reinforcement Learning, and Graph Neural Networks (GNN) etc, the company has independently developed a full-stack risk assessment and quantification platform. Currently, SiGREALTech delivers professional services to several top-tier clients within the industry.",
            content2:
                "The core team members of SiGREALTech hail from prestigious institutions such as Tsinghua University, the Chinese Academy of Sciences, Boston University, the University of California, and ETH Zurich. They have previously held key positions at renowned high-tech companies in Silicon Valley and Fortune 500 corporations. With extensive experience in publishing academic work at top international AI conferences, the team has a deep foundational background in areas such as machine learning and big data for connected vehicles.",
            content3:
                'The company is recognized as a National High-Tech Enterprise, a "Specialized, Refined, Special, and New" SME in Beijing, a Zhongguancun High-Tech Enterprise, a Zhongguancun Golden Seed Enterprise, and a technology-based enterprise certified by the Ministry of Science and Technology. SiGREALTech holds over a hundred core patents and software copyrights.',
        },
        ourCulture: {
            title: "Our Culture",
            cultureTitle: [
                "Pursue Customer Success",
                "Down-to-Earth",
                "Integrity",
                "Delayed Gratification",
                "Execution",
                "Proactivity",
            ],
            cultureIntro: ["", "", "", "", "", ""],
        },
        service: {
            services: {
                title: "Services",
                text1: "SiGREAL Tech is committed to applying artificial intelligence technology across diverse industry sectors, including transportation, industrial, and agricultural scenarios. The company independently develops innovative behavior risk assessment models and risk control solutions for various entities (including, but not limited to, humans, machines, and living organisms). These solutions delve deep into behavior patterns, pinpoint potential risk factors, and enable precise, individualized measurement of risk costs.",
                text2: `In the emerging fields of digital risk management, smart mobility, green energy aftermarket, and instant delivery, we offer one-stop risk management technology solutions. By integrating artificial intelligence algorithms with advanced product innovation and design philosophies, we optimize the existing risk management processes. This approach cultivates a new ecosystem in the digital intelligence risk control sector, establishing industry-specific algorithmic big models and comprehensive risk monitoring and control systems.`,
            },
            scopeOfService: {
                title: "Scope Of Services",
                list: [
                    {
                        title: "AI+Automotive OEM",
                        subTitle:
                            '"Data Collection and Processing—Customer Operations—Risk Management" End-to-End Solution',
                        text1: "Creating a Comprehensive Vehicle Owner Profile",
                        text2: "Reducing Vehicle Risk Costs",
                        text3: "Enriching the Vehicle Owner Service Ecosystem",
                        look: "LEARN MORE",
                    },
                    {
                        title: "AI+Mobility/Commercial Vehicle Platforms",
                        subTitle:
                            "Providing a Closed-Loop Fleet Risk Management Solution to Reduce Fleet Operational Costs",
                        text1: "Creating Driver Risk Profiles",
                        text2: "Replicating Vehicle Trips and Trajectories",
                        text3: "Providing Fleet Safe Driving Operational Solutions",
                        look: "LEARN MORE",
                    },
                    {
                        title: "AI+Logistics/Delivery Platforms",
                        subTitle:
                            "Building a full-stack risk assessment and management platform to provide safety assurance for emerging industries.",
                        text1: "Identifying Risk Entities and Risk Events",
                        text2: "Risk Alerts and Proactive Intervention",
                        text3: "Identifying Fraud Risks and Reducing Claims and Losses",
                        look: "LEARN MORE",
                    },
                ],
            },
        },
        quote: {
            title: "“AI has the potential to be one of the most important and beneficial technologies ever invented.”",
            sign: "DEMIS HASSABIS, CO-FOUNDER AND CEO, GOOGLE DEEPMIND",
        },
    },
};
export default info; //需要暴露出去
