const info = {
    CN: {
        //中文翻译
        banner: {
            title: "物流/配送行业安全保障服务",
            text: "运用人工智能技术为新兴互联网行业（外卖、快递物流等行业）定制场景化的配送员安全保障服务。",
        },
        security: {
            title: "物流/配送行业安全保障服务",
            text: "基于强化学习、图卷积神经网络等技术，为个体行为风险识别量身定制深度学习模型，形成“一人一像”的驾驶行为画像。",
            content: [
                {
                    text: "精准预测配送员驾驶风险，对配送员风险行为进行快速干预。",
                },
                {
                    text: "帮助物流、配送企业改善配送员管理体系，打造配送员配送行为的正向提升机制。",
                },
                {
                    text: "风险事件发生后，通过深度学习等AI技术监控欺诈风险，锁定欺诈事件，为降赔减损提供技术支持。",
                },
            ],
        },
    },
    EN: {
        //英文翻译
        banner: {
            title: "Logistics/Delivery Industry Safety and Security Services",
            text: "Leveraging artificial intelligence technology to tailor scenario-based safety protection services for delivery personnel in emerging internet industries, such as food delivery and courier logistics.",
        },
        security: {
            title: "Security Service",
            text: "Leveraging reinforcement learning, graph convolutional neural networks, and other advanced technologies, we customize deep learning models for individual behavior risk identification, creating personalized driving behavior profiles under a 'one person, one profile' framework.",
            content: [
                {
                    text: "Accurately predict delivery driver risk and enable rapid intervention for risky behaviors.",
                },
                {
                    text: "Assist logistics and delivery companies in improving their driver management systems, fostering positive behavior enhancement mechanisms for delivery drivers.",
                },
                {
                    text: "After a risk event occurs, monitor fraud risk using deep learning and other AI technologies to identify fraudulent activities, providing technical support for reducing losses and mitigating claims.",
                },
            ],
        },
    },
};
export default info; //需要暴露出去
