const info = {
    CN: {
        //中文翻译
        banner: {
            title: "基于多模态大模型的车联网服务",
            text: "将车机数据、行程环境数据等多模态数据深度融合，通过自监督学习、SFT & RLHF等前沿深度学习技术，建立人、车、路多模态风险量化评估模型，助力主机厂智能化客户运营。",
        },
        lovBigData: {
            title: "领先的人工智能算法模型刻画智能车主全息画像",
            list: [
                {
                    title: "多源数据采集融合",
                    text: "清理并深度融合多数据源（前装及后装）接入多维度数据",
                },
                {
                    title: "安全稳定中台",
                    text: "微服务架构，分布式存储，实时数仓，联邦学习保障数据安全性",
                },
                {
                    title: "领先的AI算法模型",
                    text: "使用行业前沿的人工智能算法，深度融合精算模型，为个体风险评估量身定制深度学习算法",
                },
                {
                    title: "定制化数据资产",
                    text: "多维度风险打分，精准绘制用户画像，全方面评估车主，深度挖掘数据价值",
                },
            ],
        },
        intelVehicle: {
            title: "智能硬件设备",
        },
        galileo: {
            titleCN: "Galileo-Mobility Platform",
            titleEN: "伽利略SaaS平台载体",
            galileoIntro: "",
        },
    },
    EN: {
        //英文翻译
        banner: {
            title: "Multimodal Large Model-Based IoV Services",
            text: "Integrate multimodal data such as vehicular data and travel environment data.，utilizing cutting-edge deep learning technologies such as self-supervised learning, SFT & RLHF.  This approch establishes a multimodal risk quantification assessment model for people, vehicles, and roads, aiding OEMs in intelligent customer operations.",
        },
        lovBigData: {
            title: "Leading artificial intelligence algorithms depict a holistic profile of smart vehicle owners",
            list: [
                {
                    title: "Multiple-Source Data Fusion",
                    text: "We strictly control every link in the process of cleaning multi-source data to ensure the credibility and availability of the data.",
                },
                {
                    title: "Reliable Data Center",
                    text: "To ensure the security and scalability of data, we use cutting-edge database techniques, such as modular microservice structure, fast distributed index mechanism, etc., in our data center.",
                },
                {
                    title: "Advanced AI Technology",
                    text: "We build customized models for User Behavior Insurance by leveraging state-of-the-art AI technology.",
                },
                {
                    title: "Customized Data Assets",
                    text: "We analyze portraits of users accurately with multidimensional behavior to guide drivers to drive safely.",
                },
            ],
        },
        intelVehicle: {
            title: "Intelligent Vehicle Equipments",
        },
        galileo: {
            title: "",
            galileoIntro:
                "For a variety of segmented business scenarios, this involves accurately depicting driver behavior profiles and providing driving scores for each journey. This helps manufacturers design personalized operational plans based on individual risk, enhancing user experience and elevating brand value.",
        },
    },
};
export default info; //需要暴露出去
